const generate = (): number => {
  const now = new Date()
  const midnight = new Date(
    now.getFullYear(),
    now.getMonth(),
    now.getDate(),
    0,
    0,
    0
  )
  const diff = now.getTime() - midnight.getTime()

  return diff / 86400000
}

export default generate
