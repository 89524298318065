import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import './CategoryPill.scss'

interface Props {
  category: string
  lang?: string
}

const CategoryPill: React.FC<Props> = ({ category, lang }) => {
  const data = useStaticQuery(graphql`
    {
      casual: file(relativePath: { eq: "casual-small-icon.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      cards: file(relativePath: { eq: "cards-small-icon.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      trivia: file(relativePath: { eq: "trivia-small-icon.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  `)
  const getHindiText = (cat: any) => {
    switch (cat) {
      case 'cards':
        return lang === 'hindi' ? 'पत्ते' : 'cards'
      default:
        return cat
    }
  }

  return (
    <div className="category-pill">
      <GatsbyImage
        image={data[category.toLowerCase()].childImageSharp.gatsbyImageData}
        alt={category}
        className={`category-icon-small ${category}`}
      />
      {getHindiText(category)}
    </div>
  )
}

export default CategoryPill
