import React, { useState, useEffect } from 'react'
import './OtherGames.scss'
import GameCard from '../../GameCard/GameCard'
import DesktopSmall from '../../GameCard/DesktopSmall'
import useRecommendGames, { GameCategories } from './useRecommendGames'

interface Props {
  gameName: string
  category: GameCategories
  lang?: string
}

const OtherGames: React.FC<Props> = ({ gameName, category, lang }) => {
  const [gameRecommendations, setGameRecommendations] = useState<any[]>()
  const [recsUpdated, setRecsUpdated] = useState(false)
  const { recommendations } = useRecommendGames(gameName, category)
  useEffect(() => {
    if (!recsUpdated) {
      setGameRecommendations(recommendations)
      setRecsUpdated(true)
    }
  }, [recommendations, recsUpdated])

  return (
    <div id="other-games">
      <div className="text">
        {lang === 'hindi' ? 'आपको यह भी पसंद आ सकता हैं' : 'You may also like'}
      </div>
      <div className="other-games-grid">
        {gameRecommendations?.map((game, i) => {
          return (
            <div
              key={game.name}
              style={{ gridColumn: `${i + 1} / ${i + 2}`, gridRow: '1 / 2' }}
            >
              <GameCard
                category={lang === 'hindi' ? game.categoryHindi : game.category}
                showCategory
                name={lang === 'hindi' ? game.nameHindi : game.name}
                showName
                showDescription={false}
                gameImage={game.image}
                gameUrl={lang === 'hindi' ? game.gameUrlHindi : game.gameUrl}
              />
            </div>
          )
        })}
      </div>
      <div className="desktop-grid">
        {gameRecommendations?.map(game => {
          return (
            <div key={game.name}>
              <DesktopSmall
                category={game.category}
                showCategory
                name={lang === 'hindi' ? game.nameHindi : game.name}
                showName
                showDescription
                description={
                  lang === 'hindi' ? game.descriptionHindi : game.description
                }
                gameImage={game.image}
                gameUrl={lang === 'hindi' ? game.gameUrlHindi : game.gameUrl}
                lang={lang}
              />
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default OtherGames
