import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import './MoreInfo.scss'
import useRandomNumber from '../../../../hooks/useRandomNumber'

interface Props {
  category?: string
  playerCount?: string
}

const MoreInfo: React.FC<Props> = ({ category = 'cards', playerCount }) => {
  const data = useStaticQuery(graphql`
    {
      cardsIcon: file(relativePath: { eq: "cards-icon.png" }) {
        childImageSharp {
          gatsbyImageData(width: 120, layout: CONSTRAINED)
        }
      }
      casualIcon: file(relativePath: { eq: "casual-icon.png" }) {
        childImageSharp {
          gatsbyImageData(width: 120, layout: CONSTRAINED)
        }
      }
      triviaIcon: file(relativePath: { eq: "trivia-icon.png" }) {
        childImageSharp {
          gatsbyImageData(width: 120, layout: CONSTRAINED)
        }
      }
      user: file(relativePath: { eq: "user.png" }) {
        childImageSharp {
          gatsbyImageData(width: 120, layout: CONSTRAINED)
        }
      }
      playingNow: file(relativePath: { eq: "playing-now.png" }) {
        childImageSharp {
          gatsbyImageData(width: 120, layout: CONSTRAINED)
        }
      }
    }
  `)

  let min = 1000
  let max = 2000

  if (category === 'cards') {
    min = 3500
    max = 5000
  }

  const playing = useRandomNumber(min, max)

  return (
    <div className="more-info">
      <div className="section">
        <GatsbyImage
          image={data[`${category}Icon`].childImageSharp.gatsbyImageData}
          loading="eager"
          alt="cards"
          className="icon"
        />
        <div>
          <div className="title">{category}</div>
          <div className="subtitle">Category</div>
        </div>
      </div>
      <div className="vertical-separator" />
      <div className="section">
        <GatsbyImage
          image={data.user.childImageSharp.gatsbyImageData}
          loading="eager"
          alt="cards"
          className="icon"
        />
        <div>
          <div className="title">{playerCount || '2 - 8'}</div>
          <div className="subtitle">Multiplayer</div>
        </div>
      </div>
      <div className="vertical-separator" />
      <div className="section">
        <GatsbyImage
          image={data.playingNow.childImageSharp.gatsbyImageData}
          loading="eager"
          alt="cards"
          className="icon"
        />
        <div>
          <div className="title">{playing}</div>
          <div className="subtitle">Playing Now</div>
        </div>
      </div>
    </div>
  )
}

export default MoreInfo
