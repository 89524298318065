import React, { useEffect, useState } from 'react'

import Layout from '../../../components/layout'
import SEO from '../../../components/seo'
import CarromPage from '../../../components/Games/Casual/Carrom/Carrom'
import { Breadcrumb } from '../../../components/Breadcrumbs/Breadcrumbs'
import {
  CARROM as CARROM_LINK,
  CASUAL,
  GAMES,
} from '../../../components/internal-links'
import { CARROM } from '../../../components/download-links'
import {
  generateBreadcrumbsSchema,
  generateFaqSchema,
  generateOrgSchema,
  generatePokerReviewSchemaAndroid,
  generatePokerReviewSchemaIos,
  generateWebsiteSchema,
} from '../../../lib/generate-schema'
import { Faq } from '../../../components/FAQs/faqs-list-interface'
import { HOMEPAGE_BREADCRUMB_TITLE } from '../../../utils/constnst.utils'

const breadcrumbs: Breadcrumb[] = [
  {
    title: HOMEPAGE_BREADCRUMB_TITLE,
    url: '/',
  },
  {
    title: 'Games',
    url: GAMES,
  },
  {
    title: 'Casual',
    url: CASUAL,
  },
  {
    title: 'Carrom',
    url: CARROM_LINK,
  },
]

const Carrom: React.FC = () => {
  const [carromFaqsSt, setCarromFaqsSt] = useState<Faq[]>([])

  useEffect(() => {
    import('../../../components/FAQs/faqs-list').then(({ carromFaqs }) =>
      setCarromFaqsSt(carromFaqs)
    )
  }, [])

  return (
    <Layout
      showBreadcrumbs
      breadcrumbs={breadcrumbs}
      apkLink={CARROM}
      desktopBreadcrumbColor="black"
    >
      <SEO
        title="Game Of Carrom Board Real Money App Download Online | How To Play [2021]"
        description="Play Carrom online, aim the striker & pocket real cash. Instant withdrawals, smooth gameplay, 100% verified profiles. Download Mega android app to win money!"
        organizationSchema={generateOrgSchema()}
        websiteSchema={generateWebsiteSchema()}
        breadcrumbsSchema={generateBreadcrumbsSchema(breadcrumbs)}
        reviewSchemaPokerAndroid={generatePokerReviewSchemaAndroid()}
        reviewSchemaPokerIos={generatePokerReviewSchemaIos()}
        faqSchema={generateFaqSchema(carromFaqsSt)}
      />
      <CarromPage />
    </Layout>
  )
}

export default Carrom
