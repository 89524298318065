import React, { useState } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import './GameCard.scss'
import './DesktopSmall.scss'
import FullscreenVideo from '../../FullscreenVideo/FullscreenVideo'
import CategoryPill from './CategoryPill'
import { formatForBigQuery, isMobile } from '../../../formatForBigQuery'

export interface GameCardProps {
  category?: string
  showCategory: boolean
  name?: string
  showName?: boolean
  description?: string
  showDescription?: boolean
  detailsBackground?: string
  gameImage?: IGatsbyImageData
  gameUrl: string
  trailerVideo?: string
  lang?: string
}

const DesktopSmall: React.FC<GameCardProps> = ({
  category,
  showCategory = false,
  name,
  description,
  gameImage,
  gameUrl,
  trailerVideo,
  lang,
  showName,
  showDescription,
  detailsBackground,
}) => {
  const data = useStaticQuery(graphql`
    {
      play: file(relativePath: { eq: "play.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  `)

  const [showVideo, setShowVideo] = useState(false)

  return (
    <Link to={gameUrl}>
      <div className="game-card DesktopSmall">
        <div className="game-image">
          <div className="cta">
            {showCategory && category && (
              <CategoryPill category={category} lang={lang} />
            )}

            {trailerVideo ? (
              <button
                className="image-button"
                type="button"
                onClick={(e): void => {
                  e.stopPropagation()
                  e.preventDefault()
                  setShowVideo(true)
                }}
              >
                <GatsbyImage
                  image={data.play.childImageSharp.gatsbyImageData}
                  className="play"
                  imgStyle={{ objectFit: 'contain' }}
                  alt="play"
                />
              </button>
            ) : null}
          </div>

          {gameImage ? (
            <GatsbyImage
              image={gameImage}
              alt={name || 'getmega'}
              className="imgFullSize"
            />
          ) : null}
        </div>
        <div className="details">
          <h2 className="name">{name}</h2>
          <div className="description">{description}</div>
          <div className="view-more">
            {lang === 'hindi' ? 'और देखें' : 'VIEW MORE'} &gt;
          </div>
        </div>

        {showVideo ? (
          <FullscreenVideo
            src={trailerVideo}
            close={() => {
              setShowVideo(false)
            }}
          />
        ) : null}
      </div>
    </Link>
  )
}

export default DesktopSmall
