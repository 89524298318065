import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import './WinnersToday.scss'
import generate from '../../ms-from-midnight'

interface Props {
  count: number
  amount: string
  amtFactor: number
  countFactor: number
}

const WinnersToday: React.FC<Props> = ({
  count,
  amount,
  amtFactor,
  countFactor,
}) => {
  const data = useStaticQuery(graphql`
    {
      coin: file(relativePath: { eq: "coin.png" }) {
        childImageSharp {
          gatsbyImageData(width: 120, layout: CONSTRAINED)
        }
      }
      w1: file(relativePath: { eq: "winner1.png" }) {
        childImageSharp {
          gatsbyImageData(width: 60, layout: CONSTRAINED)
        }
      }
      w2: file(relativePath: { eq: "winner2.png" }) {
        childImageSharp {
          gatsbyImageData(width: 60, layout: CONSTRAINED)
        }
      }
      w3: file(relativePath: { eq: "winner3.png" }) {
        childImageSharp {
          gatsbyImageData(width: 600, layout: CONSTRAINED)
        }
      }
    }
  `)

  const ms = generate()
  const amt = Math.ceil(ms * 1000 * amtFactor)
  const c = Math.ceil((ms * 1000) / countFactor)

  return (
    <div className="winners-today">
      <GatsbyImage
        image={data.coin.childImageSharp.gatsbyImageData}
        loading="eager"
        alt="coin"
        className="coin"
      />

      <div>
        <div className="text">
          {c} players won ₹
          {amt
            .toFixed(2)
            .replace(/(\d)(?=(\d{2})+\d\.)/g, '$1,')
            .replace('.00', '')}{' '}
          today
        </div>
        <div className="winners">
          <GatsbyImage
            image={data.w1.childImageSharp.gatsbyImageData}
            alt="winner 1"
            className="winner w1"
          />
          <GatsbyImage
            image={data.w2.childImageSharp.gatsbyImageData}
            alt="winner 2"
            className="winner w2"
          />
          <GatsbyImage
            image={data.w3.childImageSharp.gatsbyImageData}
            alt="winner 3"
            className="winner w3"
          />
          <div className="more">+{c - 3}</div>
        </div>
      </div>
    </div>
  )
}

export default WinnersToday
