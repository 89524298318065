import React, { useState } from 'react'
import LinkButton from '../../LinkButton/LinkButton'
import './CategoryInfo.scss'

interface Props {
  visible: React.ReactNode
  hidden?: React.ReactNode
  gameName?: string
  blogLink?: string
  apkLink?: string
  blogLabel?: string
  lang?: string
}

const ReadMoreLessSections: React.FC<Props> = ({
  visible,
  hidden,
  blogLink,
  blogLabel,
  lang,
  gameName,
  apkLink,
}) => {
  const [expand, setExpand] = useState(false)
  const toggle = () => {
    setExpand(!expand)
  }

  return (
    <div>
      <div className="visible">{visible}</div>
      {hidden && !expand ? (
        <button type="button" onClick={toggle}>
          {lang === 'hindi' ? 'और पढ़ें' : 'Read More'}
        </button>
      ) : (
        ''
      )}

      {hidden && (
        <div className={`hidden ${expand ? '' : 'd-none'}`}>{hidden}</div>
      )}

      {expand && (
        <button type="button" onClick={toggle}>
          {lang === 'hindi' ? 'कम पढ़ें' : 'Read Less'}
        </button>
      )}
      <div>
        {blogLink ? (
          <LinkButton
            className="popBtn inline-btn"
            to={blogLink}
            color="white"
            background="transparent"
          >
            {blogLabel || 'Learn to Play'}
          </LinkButton>
        ) : (
          ''
        )}
      </div>
    </div>
  )
}

export default ReadMoreLessSections
