import React from 'react'
import './Pill.scss'

interface Props {
  children: React.ReactNode
}

const Pill: React.FC<Props> = ({ children }) => {
  return <div className="pill">{children}</div>
}

export default Pill
