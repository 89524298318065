// eslint-disable-next-line
const shuffle = (array: any[]): any[] => {
  const newArray = [...array]

  for (let i = 0; i < array.length; i += 1) {
    const j = Math.floor(Math.random() * i)
    const temp = newArray[i]
    newArray[i] = newArray[j]
    newArray[j] = temp
  }

  return newArray
}

export default shuffle
