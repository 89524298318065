import React from 'react'
import HomeTestimonials from '../../../Index/Testimonials/HomeTestimonials'
import { TestimonialProps } from '../../../Testimonials/Testimonial'

interface Props {
  testimonials: TestimonialProps[]
  lang?: string
}

const GameTestimonials: React.FC<Props> = ({ testimonials, lang }) => {
  return (
    <div id="game-testimonials">
      <HomeTestimonials color="white" testimonials={testimonials} lang={lang} />
    </div>
  )
}

export default GameTestimonials
