import React, { useEffect, useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'

import './GamesIntro.scss'
import Container from 'react-bootstrap/esm/Container'
import LinkButton from '../../LinkButton/LinkButton'
import PopupDownload from '../../PopupDownload/PopupDownload'
import SendSms from '../../Index/MainScreen/Desktop/SendSms'
import { isAndroid, isIOS } from '../../../formatForBigQuery'

interface Props {
  thumbnail?: unknown
  addImageMask?: boolean
  gameName?: string
  apkLink?: string
  oneLinks?: Record<'android' | 'ios' | 'web', string>
  disableOverlay?: boolean
  lang?: string
}

const GamesIntro: React.FC<Props> = ({
  thumbnail,
  gameName,
  apkLink,
  addImageMask = false,
  disableOverlay,
  oneLinks,
  lang,
}) => {
  const style = addImageMask
    ? {
        maskImage: 'linear-gradient(rgba(0, 0, 0, 1), transparent)',
        WebkitMaskImage: 'linear-gradient(rgba(0, 0, 0, 1), transparent)',
      }
    : {}

  const data = useStaticQuery(graphql`
    {
      video: file(relativePath: { eq: "games-intro.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  `)

  const [isPopupOpen, setIsPopupOpen] = useState(false)
  const popupCode = isPopupOpen ? (
    <PopupDownload
      close={() => {
        setIsPopupOpen(false)
      }}
      gameName={gameName}
      apkLink={apkLink}
      pokerLink={oneLinks}
      rummyLink={oneLinks}
      showPokerOnly={gameName?.toLowerCase() === 'poker'}
      showRummyOnly={gameName?.toLowerCase() === 'rummy'}
      lang={lang}
    />
  ) : null

  const [platform, setPlatform] = useState('web') // default set to web
  useEffect(() => {
    if (isAndroid()) {
      setPlatform('android')
    }
    if (isIOS()) {
      setPlatform('ios')
    }
  }, [platform])

  const loginText = lang === 'hindi' ? 'लॉगिन / साइनअप' : 'LOGIN / SIGNUP'
  const playNowText = lang === 'hindi' ? 'अभी खेलें' : 'Play Now'

  return (
    <>
      <div id="games-intro">
        <GatsbyImage
          image={thumbnail || data.video.childImageSharp.gatsbyImageData}
          loading="eager"
          alt="Mega games intro"
          className="game-intro-image"
          style={style}
        />
        {!disableOverlay && (
          <div className="games-intro-overlay">
            <Container>
              {gameName ? <div className="game-name">{gameName}</div> : null}
              <div className="animated-text-wrap">
                <div className="animate-prefix">Real &nbsp;</div>
                <div className="slidingVertical">
                  <span>Friends</span>
                  <span>Connections</span>
                  <span>Money</span>
                  <span>Thrill</span>
                </div>
              </div>
              <div className="game-button">
                {gameName === 'Poker' ? (
                  <>
                    <div className="d-none d-md-block">
                      <LinkButton
                        className="popBtn inline-btn"
                        onClick={() => {
                          setIsPopupOpen(true)
                        }}
                      >
                        {playNowText}
                      </LinkButton>
                    </div>
                    <div className="d-block d-md-none">
                      <SendSms
                        smsLink={oneLinks}
                        game={gameName}
                        downloadBtnText={playNowText}
                        lang={lang}
                      />
                    </div>
                  </>
                ) : (
                  <SendSms
                    smsLink={oneLinks}
                    game={gameName}
                    downloadBtnText={
                      platform === 'web' ? loginText : playNowText
                    }
                    lang={lang}
                  />
                )}
              </div>
            </Container>
          </div>
        )}
      </div>
      {popupCode}
    </>
  )
}

export default GamesIntro
