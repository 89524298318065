import React, { useEffect, useMemo, useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import './Contests.scss'
import { Container } from 'react-bootstrap'
import Title from '../Title/Title'
import useRandomNumber from '../../hooks/useRandomNumber'
import PopupDownload from '../PopupDownload/PopupDownload'
import {
  formatForBigQuery,
  isAndroid,
  isIOS,
  isMobile,
} from '../../formatForBigQuery'

interface Props {
  gameName?: string
  apkLink?: string
  oneLinks?: Record<'android' | 'ios' | 'web', string>
  lang?: string
}

const Contests: React.FC<Props> = ({ gameName, apkLink, oneLinks, lang }) => {
  const data = useStaticQuery(graphql`
    {
      contestsIcon: file(relativePath: { eq: "contests-icon.png" }) {
        childImageSharp {
          gatsbyImageData(width: 330, layout: CONSTRAINED)
        }
      }
      infinity: file(relativePath: { eq: "infinity.png" }) {
        childImageSharp {
          gatsbyImageData(width: 60, layout: CONSTRAINED)
        }
      }
      users: file(relativePath: { eq: "users.png" }) {
        childImageSharp {
          gatsbyImageData(width: 12, layout: CONSTRAINED)
        }
      }
      cup: file(relativePath: { eq: "cup.png" }) {
        childImageSharp {
          gatsbyImageData(width: 12, layout: CONSTRAINED)
        }
      }
    }
  `)

  const c1 = useRandomNumber(1000, 2000)
  const c2 = useRandomNumber(1000, 2000)

  const [platform, setPlatform] = useState('web') // default set to web

  useEffect(() => {
    if (isAndroid()) {
      setPlatform('android')
    }
    if (isIOS()) {
      setPlatform('ios')
    }
  }, [platform])

  const [isPopupOpen, setIsPopupOpen] = useState(false)
  const handlePlay = () => {
    setIsPopupOpen(true)
  }

  const popupCode = isPopupOpen ? (
    <PopupDownload
      close={() => {
        setIsPopupOpen(false)
      }}
      gameName={gameName}
      apkLink={apkLink}
      pokerLink={oneLinks}
      rummyLink={oneLinks}
      showPokerOnly={gameName?.toLowerCase() === 'poker'}
      showRummyOnly={gameName?.toLowerCase() === 'rummy'}
      lang={lang}
    />
  ) : null

  const gameContestValues: {
    [index: string]: Array<{ bb_pr: string; minBuyIn: string }>
  } = useMemo(
    () => ({
      Poker: [
        { bb_pr: '₹2 Big Blind', minBuyIn: '₹25 Min Buy-In' },
        { bb_pr: '₹5 Big Blind', minBuyIn: '₹50 Min Buy-In' },
      ],
      PokerHindi: [
        { bb_pr: '₹2 बिग ब्लाइंड', minBuyIn: '₹25 मिनट बाय-इन' },
        { bb_pr: '₹5 बिग ब्लाइंड', minBuyIn: '₹50 मिनट बाय-इन' },
      ],
      Rummy: [
        { bb_pr: '1 Point = ₹0.25', minBuyIn: '₹20 Min Buy-In' },
        { bb_pr: '1 Point = ₹1', minBuyIn: '₹80 Min Buy-In' },
      ],
      RummyHindi: [
        { bb_pr: '1 पॉइंट = ₹0.25', minBuyIn: '₹20 मिनट बाय-इन' },
        { bb_pr: '1 पॉइंट = ₹1', minBuyIn: '₹80 मिनट बाय-इन' },
      ],
      Carrom: [
        { bb_pr: '1 Point = ₹0.05', minBuyIn: '₹1 Min Buy-In' },
        { bb_pr: '1 Point = ₹1', minBuyIn: '₹10 Min Buy-In' },
      ],
      GoPool: [
        { bb_pr: '1 Point = ₹0.05', minBuyIn: '₹1 Min Buy-In' },
        { bb_pr: '1 Point = ₹1', minBuyIn: '₹10 Min Buy-In' },
      ],
      Warship: [
        { bb_pr: '1 Point = ₹0.10', minBuyIn: '₹2 Min Buy-In' },
        { bb_pr: '1 Point = ₹0.50', minBuyIn: '₹10 Min Buy-In' },
      ],
      ABCRummy: [
        { bb_pr: '1 Point = ₹0.01', minBuyIn: '₹1 Min Buy-In' },
        { bb_pr: '1 Point = ₹0.50', minBuyIn: '₹9 Min Buy-In' },
      ],
      DotsAndDash: [
        { bb_pr: '1 Point = ₹0.10', minBuyIn: '₹2 Min Buy-In' },
        { bb_pr: '1 Point = ₹0.50', minBuyIn: '₹10 Min Buy-In' },
      ],
      FruitKaat: [
        { bb_pr: '1 Point = ₹0.01', minBuyIn: '₹1 Min Buy-In' },
        { bb_pr: '1 Point = ₹0.50', minBuyIn: '₹10 Min Buy-In' },
      ],
      GKGuru: [{ bb_pr: '1 Point = ₹0.10', minBuyIn: '₹1 Min Buy-In' }],
      OneTwoThree: [{ bb_pr: '1 Point = ₹0.10', minBuyIn: '₹1 Min Buy-In' }],
      PicMe: [{ bb_pr: '1 Point = ₹0.10', minBuyIn: '₹1 Min Buy-In' }],
    }),
    []
  )

  const gameLang = lang === 'hindi' ? `${gameName}Hindi` : gameName || gameName

  const contestDescGame =
    gameName === 'Poker' ? 'big blind tables.' : 'higher point tables.'
  const contestDescGameHindi =
    gameName === 'Poker' ? 'बिग ब्लाइंड टेबल चुनें।' : 'हाई पॉइंट टेबल चुनें।'

  return (
    <div id="contests">
      <Container>
        <GatsbyImage
          image={data.contestsIcon.childImageSharp.gatsbyImageData}
          alt="contests icon"
          className="contests-icon"
        />

        <Title color="black" className="c-title">
          {lang === 'hindi' ? 'प्रतियोगिता' : 'Contests'}
        </Title>

        <div className="subtitle">
          {lang === 'hindi' ? 'कैश टेबल्स' : 'Cash Tables'}
        </div>
        <div className="description">
          {lang === 'hindi'
            ? ` कृपया कैश टेबल्स में शामिल हों और बड़ी जीत के लिए, `
            : `Join & leave as you please. For bigger winnings, choose `}
          {lang === 'hindi' ? contestDescGameHindi : contestDescGame}
        </div>

        {gameLang && gameContestValues[gameLang] ? (
          <div className="list">
            {gameContestValues[gameLang].map(
              (ct: { bb_pr: string; minBuyIn: string }, i) => (
                <div
                  className="ct"
                  key={`${gameLang}_${ct.bb_pr}_${ct.minBuyIn}`}
                >
                  <div className="icon-container">
                    <GatsbyImage
                      image={data.infinity.childImageSharp.gatsbyImageData}
                      alt="infinity"
                      className="table-icon"
                      imgStyle={{ objectFit: 'contain' }}
                    />
                  </div>
                  <div className="point">{ct.bb_pr}</div>
                  <div />
                  <div className="buy-in">{ct.minBuyIn}</div>

                  <a
                    href={oneLinks?.web}
                    target="_blank"
                    className="play d-none d-md-block"
                    rel="noreferrer"
                  >
                    {lang === 'hindi' ? 'खेलें' : 'play'}
                  </a>
                  <a
                    href={
                      platform === 'android' ? oneLinks?.android : oneLinks?.ios
                    }
                    className="play d-block d-md-none"
                  >
                    {lang === 'hindi' ? 'खेलें' : 'play'}
                  </a>

                  <div />
                  <div className="available">
                    <span>
                      {lang === 'hindi' ? '24x7 उपलब्ध है' : 'Available 24x7'}
                    </span>
                    <div className="dot">•</div>
                    <GatsbyImage
                      image={data.users.childImageSharp.gatsbyImageData}
                      alt="users"
                      className="users-icon"
                      imgStyle={{ objectFit: 'contain' }}
                    />
                    <span>{i ? c1 : c2}</span>
                  </div>
                </div>
              )
            )}
          </div>
        ) : null}
      </Container>
      {popupCode}
    </div>
  )
}

export default Contests
