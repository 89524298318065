import React from 'react'
import './CategoryInfo.scss'
import * as links from '../../internal-links'
import ReadMoreLessSections from './ReadMoreLessSections'
import { CARROM } from '../../download-links'

const CarromInfo: React.FC = () => {
  const visible = (
    <>
      <h2 className="page-title">Mega Carrom Party</h2>
      <p>
        14th March 2021 was the day when <strong>#MegaCarromParty</strong> broke
        the internet! This one-of-a-kind livestream in the real money gaming
        industry saw Tanmay Bhat invite his fans to videochat & play Carrom with
        him on the Mega app.
      </p>
      <p>
        With many fans eager to interact with Tanmay, SuperChats were pouring in
        non-stop. Moreover, nobody would’ve imagined that the livestream will
        break the record for the highest Indian SuperChat ever: ₹50,000! And who
        could forget the grand entry of The Great Khali! Tanmay and the viewers
        were shocked to the core when the former World Wrestling Champion just
        casually dropped in & spent a few minutes striking Carrom coins, and
        bonking heads. No wonder this video notched up half a million views in
        no time!
      </p>
      <p>
        At the end of the livestream, Tanmay also announced a Challenge with a
        bumper prize of ₹10,00,000! Anyone could host their #MegaCarromParty
        using the Mega app, livestream it on YouTube, and win the mind-boggling
        amount by garnering the highest number of views. The winner & 2
        runners-up would also get to host livestreams with Tanmay, Suhani Shah &
        Nishant Tanwar! Eventually Live Insaan won the Challenge with over 2.8
        million views. This was just the beginning; on Mega, where the Party
        never ends.
      </p>
    </>
  )
  const hidden = (
    <>
      <h2 className="page-title">Learn more about Carrom</h2>
      <p>
        Ready to sweep the board with your striker skills? Pocket real money
        with carrom coins?
      </p>
      <p>
        Full of wholesome fun every turn, Carrom is India’s family favourite
        board game. Get together, wait for your turn, strike and shine.
      </p>
      <p>
        Played between 2 to 4 players, Mega Carrom is about pocketing as many
        coins as you can, faster than your opponents. Whether it’s the black
        coins, white coins or the red queen, your score is decided by the number
        written on the coin you pocket. And if you do take the queen out, make
        sure there is a cover behind.
      </p>
      <p>
        Finding the right angle to strike for a truly dramatic hit, or
        rebounding the striker with a firm flick of your finger - the game of
        Carrom rewards precision, strategy and skill, and packs a punch for
        players of all levels. Add to it the thrill of real money stakes,
        against real players in real time, and we have a truly exciting real
        money Carrom game for you.
      </p>
      <p>Aim. Strike. Pocket. Repeat.</p>
      <p>
        Download Mega to play your favourite casual game of Carrom with real
        money thrills, and win exciting real cash rewards.
      </p>

      <h2 className="page-title">Why play Carrom on Mega?</h2>
      <h3>Smooth controls, Realistic gameplay</h3>
      <p>
        With user-friendly controls, great physics and a cool steampunk theme,
        Carrom on Mega is designed for a fun, smooth gameplay experience on your
        Android device. Unique, lifelike graphics and intuitive touch controls
        help you aim and strike accurately, so that you maximise the fun, and
        enjoy real cash carrom games without any worries.
      </p>

      <h3>A table for everyone</h3>
      <p>
        Whether you are playing for some light-hearted fun or looking for some
        real stake wins, we have you covered. With buy-ins ranging from ₹1 to
        ₹100 &amp; beyond, we have a real money carrom table that’s perfect for
        you. Practice in low stakes contests, upgrade your skills and win big.
      </p>

      <h3>Real players, Real time</h3>
      <p>
        Mega features real money carrom games with only 100% verified profiles
        of legally verified age. All games are played in multiplayer mode, where
        you are matched against real players in real time. No bots, no tricks.
        Just a pure game of carrom with real money rewards.
      </p>

      <h3>Real wins, instant withdrawals</h3>
      <p>
        The winnings of your game are credited to your Mega wallet just after
        you leave the table. The winnings can be seamlessly withdrawn to your
        PayTM wallet, or through UPI or directly to your bank account, all
        within a few minutes.
      </p>
    </>
  )
  return (
    <div className="category-info">
      <ReadMoreLessSections
        visible={visible}
        hidden={hidden}
        gameName="Carrom"
        blogLink={links.CARROM_LEARN}
        apkLink={CARROM}
        blogLabel="Learn Carrom"
      />
    </div>
  )
}

export default CarromInfo
