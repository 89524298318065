const skills = {
  '123': ['Creativity', 'Perception', 'Strategy'],
  GK: ['Creativity', 'Perception', 'Strategy'],
  PicMe: ['Creativity', 'Perception', 'Strategy'],
  Poker: ['Strategy', 'Logic', 'Creativity'],
  Rummy: ['Strategy', 'Logic', 'Creativity'],
  Carrom: ['Strategy', 'Motor'],
  Pool: ['Strategy', 'Creativity', 'Motor'],
  Warship: ['Strategy', 'Creativity', 'Motor'],
  HopPop: ['Strategy', 'Creativity', 'Motor'],
  ABCRummy: ['Strategy', 'Creativity', 'Motor'],
  DotsAndDash: ['Strategy', 'Creativity', 'Motor'],
  FruitKaat: ['Strategy', 'Creativity', 'Motor'],
}

export default skills
