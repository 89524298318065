import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { useEffect, useState } from 'react'
import { isMobile } from '../../../../formatForBigQuery'
import useRandomNumber from '../../../../hooks/useRandomNumber'
import shuffle from '../../../../lib/shuffle'
import * as links from '../../../internal-links'
import gameDescription from '../../games-description'

export interface RecommendedGame {
  name: string
  category: string
  description: string
  gameUrl: string
  image: typeof GatsbyImage
}

export const recsGameNames = {
  Poker: "Hold 'em Poker",
  Rummy: 'Rummy',
  Carrom: 'Carrom',
  GoPool: 'GoPool',
  Warship: 'Warship',
  'Dots & Dash': 'Dots & Dash',
  FruitKaat: 'FruitKaat',
  ABCRummy: 'ABC Rummy',
  GK: 'GK',
  '123': '123',
  PicMe: 'PicMe',
}

export type GameCategories = 'Cards' | 'Casual' | 'Trivia'

const useRecommendGames = (gameName: string, category: GameCategories) => {
  const data = useStaticQuery(graphql`
    {
      Poker: file(
        relativePath: { eq: "Mobile Poster Assets/TEXAS_YOUTUBE Copy.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 984, layout: CONSTRAINED)
        }
      }
      Rummy: file(
        relativePath: { eq: "Mobile Poster Assets/preview copy 2.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 984, layout: CONSTRAINED)
        }
      }
      PokerDesk: file(
        relativePath: { eq: "Desktop Recommendation assets/poker-image.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 984, layout: CONSTRAINED)
        }
      }
      RummyDesk: file(
        relativePath: { eq: "Desktop Recommendation assets/rummy-image.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 984, layout: CONSTRAINED)
        }
      }
    }
  `)

  const [isM, setIsM] = useState(false)
  useEffect(() => {
    setIsM(isMobile())
  }, [])

  const cards: RecommendedGame[] = [
    {
      name: "Hold 'em Poker",
      category: 'cards',
      description: gameDescription.Poker.short,
      gameUrl: links.POKER,
      image: data[isM ? 'Poker' : 'PokerDesk'].childImageSharp.gatsbyImageData,
    },
    {
      name: 'Rummy',
      category: 'cards',
      description: gameDescription.Rummy.short,
      gameUrl: links.RUMMY,
      image: data[isM ? 'Rummy' : 'RummyDesk'].childImageSharp.gatsbyImageData,
    },
  ]

  return {
    recommendations: shuffle([
      cards.filter(g => g.name !== gameName)[
        useRandomNumber(0, cards.length - 1 - (category === 'Cards' ? 1 : 0))
      ],
    ]),
  }
}

export default useRecommendGames
