const gameDescription = {
  Poker: {
    long: 'You don’t need a royal flush to flush down your opponents. Are you that strong player who turns weak cards to their advantage? Let the dealing begin.',
    short:
      'From high card to royal flush, turn any hand to your advantage & flush down your opponents.',
  },
  Rummy: {
    long: 'Suit up for the finest Indian rummy experience on the planet. From pure sequences to smart sets, keep those card combos coming!',
    short:
      'Suit up for the finest rummy experience ever, and keep those sets & sequences coming!',
  },
  Pool: {
    long: 'Step inside this exclusive high society club, where a select few gamers get to play pool. Aim well, shoot smart, and experience pool like never before!',
    short:
      'Step inside this exclusive high society club and experience pool like never before!',
  },
  Carrom: {
    long: 'Your childhood challenge is back with a twist. Aim well, strike smart, and win this timeless game.',
    short:
      'Your childhood challenge is back with a twist. Aim well, strike smart & win the board.',
  },
  FruitKaat: {
    long: 'Forget candies, slice yummy fruits instead! Swipe left & right, up & down to match 3 fruits of the same type, and crush your opponents with your extreme tile matching skills.',
    short:
      'Forget candies; swipe to match 3 fruits & crush your opponents with your tile matching skills.',
  },
  'Dots & Dash': {
    long: 'Open your school notebook’s last page — on your mobile this time. The dots are ready; mark your dashes smartly and win the game of your childhood.',
    short:
      'The dots are ready; mark your dashes smartly & win the game of your childhood.',
  },
  Warship: {
    long: 'This is WAR! Aim well & launch your deadly missiles to sink the enemy ships. Defend your ships well, and be the king of the seas.',
    short:
      'This is WAR! Defend your ships & launch your deadly missiles to sink the enemy ships.',
  },
  ABCRummy: {
    long: 'Kids play with trump cards, adults play poker or rummy, but legends play ABC Rummy! Use your knowledge of A to Z to sort the letters and make the right sets & sequences.',
    short:
      'Use your knowledge of A to Z to sort the letters and make the right sets & sequences.',
  },
  GK: {
    long: 'If you know, the money will flow! Have fun with questions from cricket, Bollywood, geography & more, to win big!',
    short:
      'If you know, the money will flow! Have fun with questions from cricket, Bollywood & more.',
  },
  '123': {
    long: 'Never got a chance to prove your Math teacher wrong? Answer these exciting math questions quickly to celebrate your sweet revenge.',
    short:
      'Prove your Math teacher wrong! Answer exciting math questions & celebrate your sweet revenge.',
  },
  PicMe: {
    long: 'Pick the right pic & win this game super quick! Treat your eyes to loads of picture quizzes - from sweets & flowers to logos & cars.',
    short:
      'Pick the right pic & win this game super quick! Treat your eyes to loads of picture quizzes.',
  },
}

export default gameDescription
