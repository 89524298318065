import React, { useState } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'

import './GameCard.scss'
import FullscreenVideo from '../../FullscreenVideo/FullscreenVideo'
import CategoryPill from './CategoryPill'
import { formatForBigQuery, isMobile } from '../../../formatForBigQuery'

export interface GameCardProps {
  category?: string
  showCategory: boolean
  name?: string
  showName: boolean
  description?: string
  showDescription: boolean
  detailsBackground?: string
  gameImage?: FluidObject
  trailerVideo?: string
  gameUrl: string
}

const GameCard: React.FC<GameCardProps> = ({
  category,
  showCategory = false,
  name,
  showName = false,
  description,
  showDescription = false,
  // detailsBackground,
  gameImage,
  trailerVideo,
  gameUrl,
}) => {
  const data = useStaticQuery(graphql`
    {
      play: file(relativePath: { eq: "play.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  `)

  // const detailsBg =
  //   (detailsBackground && { background: detailsBackground }) || {}

  const [showVideo, setShowVideo] = useState(false)

  return (
    <Link to={gameUrl} className="game-card">
      <div className="game-image">
        <div className="cta">
          {showCategory && category && <CategoryPill category={category} />}

          {trailerVideo ? (
            <button
              className="image-button"
              type="button"
              onClick={(e): void => {
                e.stopPropagation()
                e.preventDefault()
                setShowVideo(true)
              }}
            >
              <GatsbyImage
                image={data.play.childImageSharp.gatsbyImageData}
                className="play"
                imgStyle={{ objectFit: 'contain' }}
                alt="play"
              />
            </button>
          ) : null}
        </div>

        {gameImage ? (
          <GatsbyImage
            image={gameImage}
            alt={name}
            // style={{ minHeight: '100%', width: '100%' }}
            imgStyle={{ objectFit: 'contain' }}
          />
        ) : null}
      </div>
      {showName && (
        <div className="details">
          <h2 className="name">{name}</h2>
          {showDescription && <div className="description">{description}</div>}
        </div>
      )}

      {showVideo ? (
        <FullscreenVideo
          close={() => {
            setShowVideo(false)
          }}
          src={trailerVideo}
        />
      ) : null}
    </Link>
  )
}

export default GameCard
